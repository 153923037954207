import emailjs from "@emailjs/browser"

async function sendMessage(message) {
	return emailjs.send(
		"service_gw6b2er",
		"template_nq42sx7",
		{
			...message,
		},
		"IAXON0N8z7hiLL6Kj"
	)
}

export default sendMessage
