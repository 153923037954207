export const color = {
	blue: {
		default: "#2548FF",
		hover: "#2241E4",
		pressed: "#2944D1",
	},
	darkBlue: {
		default: "2040e5",
		hover: "#2241E4",
		pressed: "#2944D1",
	},
	black: {
		default: "#141B42",
		hover: "#141B42",
		pressed: "#141B42",
	},
	night: {
		default: "#0B1237",
		hover: "#0B1237",
		pressed: "#0B1237",
	},
	grey: {
		default: "#828DA4",
		hover: "#828DA4",
		pressed: "#828DA4",
	},
	transparent: {
		default: "transparent",
		hover: "transparent",
		pressed: "transparent",
	},
	main: {
		text1: "#0B1237",
		text2: "#474D6C",
		text3: "#81869D",
		primary1: "#2548FF",
		primary2: "#DD3C9A",
		primaryHover: "#0A31FF",
		primaryPressed: "#0024E0",
	},
	light: {
		n800: "#FAFBFC",
		n700: "#F8F9FC",
		n600: "#F2F4F7",
		n500: "#E7E9EF",
		n400: "#CACFDE",
	},
	dark: {
		n200: "#9096A7",
		n100: "#6F748B",
		n30: "#5A5D6D",
		n10: "#36383F",
		default: "#374562",
		hover: "#374562",
		pressed: "#374562",
	},
	white: {
		n90: "rgba(255,255,255,0.90)",
		n80: "rgba(255,255,255,0.80)",
		n70: "rgba(255,255,255,0.70)",
		n60: "rgba(255,255,255,0.60)",
		n50: "rgba(255,255,255,0.50)",
		n40: "rgba(255,255,255,0.40)",
		n20: "rgba(255,255,255,0.20)",
		default: "#ffffff",
		hover: "#EAEDFF",
		pressed: "#E1E5FD",
	},
}

export const shadow = {
	blue: {
		default: "0px 8px 16px rgba(37, 72, 255, 0.4)",
		hover: "0px 12px 16px rgba(37, 72, 255, 0.3)",
		pressed: "none",
	},
	blueLight: {
		default: "0px 8px 20px rgba(37, 72, 255, 0.1)",
		hover: "none",
		pressed: "none",
	},
	none: {
		default: "none",
		hover: "none",
		pressed: "none",
	},
}

const skin = {
	blue: {
		border: color.blue,
		background: color.blue,
		text: color.white,
		shadow: shadow.blue,
	},
	blueInverse: {
		border: color.blue,
		background: color.white,
		text: color.blue,
		shadow: shadow.blueLight,
	},
	blueInverseDark: {
		border: color.transparent,
		background: color.white,
		text: color.blue,
		shadow: shadow.blueLight,
	},
	dark: {
		border: color.blue,
		background: color.blue,
		text: color.white,
		shadow: shadow.blue,
	},
	blueGhost: {
		border: color.transparent,
		background: color.white,
		text: color.blue,
		shadow: shadow.none,
	},
	darkGhost: {
		border: color.transparent,
		background: color.white,
		text: color.dark,
		shadow: shadow.none,
	},
	grey: {
		border: color.grey,
		background: color.white,
		text: color.black,
		shadow: shadow.none,
	},
	alert: {
		border: color.transparent,
		background: color.transparent,
		text: color.white,
		shadow: shadow.none,
	},
}

export default skin
