import React from "react"
import { Field, ErrorMessage } from "formik"
import { bool, shape, string, func } from "prop-types"

const FormItem = ({ error, touched, item, value, setFieldValue, formId }) => {
	const defRegex = {
		email:
			"^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$", // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#Basic_validation
		tel: "[+][0-9]{3} [0-9]{3} [0-9]{3} [0-9]{3}",
		ico: "[0-9]{3} [0-9]{3} [0-9]{2}",
	}
	const defFormat = {
		telSpaceAt: [4, 7, 10],
		icoSpaceAt: [3, 6],
	}

	const addSpaces = (str, spaces) => {
		return str
			.replace(/\s/g, "")
			.split("")
			.map((char, index) => {
				if (spaces.includes(index) && char !== " ") {
					return ` ${char}`
				}
				return char
			})
			.join("")
	}

	const validationRule = RegExp(defRegex[item.validation] || item.validation)
	/*
	 * Validate function will only be executed on mounted fields.
	 * If a field is unmounted during the flow, it will not be validated.
	 * https://jaredpalmer.com/formik/docs/guides/validation
	 */
	const validate = val => {
		if (
			item.required &&
			(typeof val === "undefined" || val === "" || val === null)
		) {
			return item.requiredMessage
		}
		if (item.validation !== "" && !validationRule.test(val)) {
			return item.validationMessage
		}
		return null
	}
	return (
		<div className={`form-item ${item.visualStyle}`}>
			{item.label && (
				<div className="form-item__inner-row">
					<label className="form-label" htmlFor={`${item.name}`}>
						{item.label}
					</label>
				</div>
			)}
			<div className="form-item__inner-row">
				{item.field === "select" ? (
					<Field
						className={`form-input ${
							touched && typeof error !== "undefined"
								? "invalid-input"
								: "valid-input"
						}`}
						component={item.field}
						type={item.type}
						name={`${item.name}`}
						id={`${formId}-${item.name}`}
						placeholder={item.placeholder}
						validate={validate}
						value={value}
						error={error}
					>
						{item.options.map(option => (
							<option value={option.value} key={option.value}>
								{option.label}
							</option>
						))}
					</Field>
				) : (
					<Field
						className={`form-input ${
							touched && typeof error !== "undefined"
								? "invalid-input"
								: "valid-input"
						}`}
						component={item.field}
						type={item.type}
						name={`${item.name}`}
						id={`${formId}-${item.name}`}
						placeholder={item.placeholder}
						validate={validate}
						value={value}
						error={error}
						onBlur={() => {
							if (item.validation === "tel") {
								setFieldValue(item.name, addSpaces(value, defFormat.telSpaceAt))
							} else if (item.validation === "ico") {
								setFieldValue(item.name, addSpaces(value, defFormat.icoSpaceAt))
							}
						}}
					/>
				)}

				<ErrorMessage
					name={`${item.name}`}
					component="p"
					className="form-field-error"
				/>
			</div>
		</div>
	)
}

FormItem.propTypes = {
	item: shape({
		name: string.isRequired,
		label: string.isRequired,
		field: string.isRequired,
		placeholder: string.isRequired,
		validation: string.isRequired,
		validationMessage: string.isRequired,
		required: bool.isRequired,
		requiredMessage: string.isRequired,
	}).isRequired,
	value: string,
	error: string,
	touched: bool,
	formId: string.isRequired,
	setFieldValue: func.isRequired,
}

FormItem.defaultProps = {
	value: "",
	error: undefined,
	touched: false,
}

export default FormItem
