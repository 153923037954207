export const menuAction = store => {
	const newValue = !store.state.menuClosed
	store.setState({ menuClosed: newValue })
}

export const modalOpen = (store, closed) => {
	store.setState({ modalOpen: closed })
}

export const viewportDimensionAction = store => {
	const isClient = typeof window === "object"
	const newValue = {
		width: isClient ? window.innerWidth : 0,
		height: isClient ? window.innerHeight : 0,
	}
	store.setState({ windowDimensions: newValue })
}
