import { createGlobalStyle } from "styled-components"
import { color } from "../_globalSettings/skin"
import { breakpoint, useZIndex } from "../_globalSettings/var"
import { typography } from "../_globalSettings/typography"

const globalStyles = createGlobalStyle`
	* {
		box-sizing: border-box;
	}
	*:before {
		box-sizing: border-box;
	}
	*:after {
		box-sizing: border-box;
	}
	html,
	body {
		margin: 0;
		padding: 0;
		min-height: 100%;
		height: 100%;
		${props => (props.modalOpen ? "overflow: hidden;" : "")}
	}
	html {
		overflow-y: scroll;
		overflow-x: hidden;
	}
	body {
		font-weight: 400;
		font-family: "Graphik Web", sans-serif, serif;
		-ms-text-size-adjust: 100%;
		-webkit-text-size-adjust: 100%;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	article,
	aside,
	details,
	figcaption,
	figure,
	footer,
	header,
	main,
	menu,
	nav,
	section,
	summary {
		display: block;
	}
	audio,
	canvas,
	progress,
	video {
		display: inline-block;
	}
	audio:not([controls]) {
		display: none;
		height: 0;
	}
	progress {
		vertical-align: baseline;
	}
	[hidden],
	template {
		display: none;
	}
	a {
		-webkit-text-decoration-skip: objects;
		color: ${color.blue.default};
		text-decoration: underline;
	}
	a:active,
	a:hover {
		outline-width: 0;
	}
	abbr[title] {
		border-bottom: none;
		text-decoration: underline;
		text-decoration: underline dotted;
	}
	b,
	strong {
		font-weight: inherit;
		font-weight: bolder;
	}
	dfn {
		font-style: italic;
	}
	mark {
		background-color: ${color.black.default};
		color: ${color.white.default};
	}
	small {
		font-size: 80%;
	}
	sub,
	sup {
		font-size: 75%;
		line-height: 0;
		position: relative;
		vertical-align: baseline;
	}
	sub {
		bottom: -0.25em;
	}
	sup {
		top: -0.5em;
	}
	img {
		border-style: none;
		max-width: 100%;
	}
	svg:not(:root) {
		overflow: hidden;
	}
	code,
	kbd,
	pre,
	samp {
		font-family: monospace, monospace;
		font-size: 1em;
	}
	figure {
		margin: 1em 40px;
	}
	hr {
		border-top: 1px solid ${color.light.n500};
		box-sizing: content-box;
		height: 0;
		overflow: visible;
	}
	button,
	input,
	optgroup,
	select,
	textarea {
		font: inherit;
		margin: 0;
	}
	optgroup {
		font-weight: 700;
	}
	button,
	input {
		overflow: visible;
	}
	button,
	select {
		text-transform: none;
	}
	[type="reset"],
	[type="submit"],
	button,
	html [type="button"] {
		-webkit-appearance: button;
	}
	[type="button"]::-moz-focus-inner,
	[type="reset"]::-moz-focus-inner,
	[type="submit"]::-moz-focus-inner,
	button::-moz-focus-inner {
		border-style: none;
		padding: 0;
	}
	[type="button"]:-moz-focusring,
	[type="reset"]:-moz-focusring,
	[type="submit"]:-moz-focusring,
	button:-moz-focusring {
		outline: 1px dotted ButtonText;
	}
	fieldset {
		border: 1px solid silver;
		margin: 0 2px;
		padding: 0.35em 0.625em 0.75em;
	}
	legend {
		box-sizing: border-box;
		color: inherit;
		display: table;
		max-width: 100%;
		padding: 0;
		white-space: normal;
	}
	textarea {
		overflow: auto;
	}
	[type="checkbox"],
	[type="radio"] {
		box-sizing: border-box;
		padding: 0;
	}
	[type="number"]::-webkit-inner-spin-button,
	[type="number"]::-webkit-outer-spin-button {
		height: auto;
	}
	[type="search"] {
		-webkit-appearance: textfield;
		outline-offset: -2px;
	}
	[type="search"]::-webkit-search-cancel-button,
	[type="search"]::-webkit-search-decoration {
		-webkit-appearance: none;
	}
	::-webkit-input-placeholder {
		color: inherit;
		opacity: 0.54;
	}
	::-webkit-file-upload-button {
		-webkit-appearance: button;
		font: inherit;
	}

	/* project override of default reset */

	@font-face {
		font-family: "Graphik Web";
		font-style: normal;
		font-stretch: normal;
		font-weight: 400;
		font-display: fallback;
		src: url("/font/commercial-type-2005-OGMWZN-web/Graphik-Regular-Web.woff2") format("woff2"),
			url("/font/commercial-type-2005-OGMWZN-web/Graphik-Regular-Web.woff") format("woff");
		/*unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
			U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
			U+2215, U+FEFF, U+FFFD;*/
		text-rendering: optimizeLegibility;
	}
	@font-face {
		font-family: "Graphik Web";
		font-style: normal;
		font-stretch: normal;
		font-weight: 500;
		font-display: fallback;
		src: url("/font/commercial-type-2005-OGMWZN-web/Graphik-Medium-Web.woff2") format("woff2"),
			url("/font/commercial-type-2005-OGMWZN-web/Graphik-Medium-Web.woff") format("woff");
		/*unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
			U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
			U+2215, U+FEFF, U+FFFD;*/
		text-rendering: optimizeLegibility;
	}
	@font-face {
  	font-family: "Graphik Web";
  	src: url("/font/commercial-type-2005-OGMWZN-web/Graphik-Semibold-Web.woff2") format("woff2"),
    	url("/font/commercial-type-2005-OGMWZN-web/Graphik-Semibold-Web.woff") format("woff");
  	font-weight: 600;
  	font-style: normal;
  	font-stretch: normal;
	}
	@font-face {
		font-family: "Graphik Web";
		src: url("/font/commercial-type-2005-OGMWZN-web/Graphik-Bold-Web.woff2") format("woff2"),
			url("/font/commercial-type-2005-OGMWZN-web/Graphik-Bold-Web.woff") format("woff");
		font-weight: 700;
		font-style: normal;
		font-stretch: normal;
	}

	p {
		margin: 0;
	}

	ul,ol {
		margin: 0;
		padding: 0;
	}

	table {
		width: 100%;
		margin: auto;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 0;
		padding: 0 0 5px 0;
		font-weight: 700;
	}
	h1, .h1 {
		font-size: 48px;
		line-height: 50px;
		padding: 0 0 10px 0;
	}
	h2, .h2 {
		font-size: 39px;
		line-height: 48px;
		padding: 0 0 25px 0;
	}
	h3, .h3 {
		font-weight: 600;
		font-size: 33px;
		line-height: 130%;
		color: ${color.main.text1};
	}
	h4, .h4 {
		font-size: 25px;
		line-height: 31px;
	}
	h5, .h5 {
		font-size: 21px;
		line-height: 30px;
	}
	h6, .h6 {
		font-size: 16px;
		line-height: 22px;
	}
	@media (max-width: ${breakpoint.min.to}px) {
		h2, .h2 {
		font-size: 31px;
		line-height: 38px;
			padding: 0 0 50px 0;
		}
	}
	#page-wrap{
		min-height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex: 1 100%;
	}
	#___gatsby,
	#gatsby-focus-wrapper,
	#outer-container,
	#inner-container {
		min-height: 100%;
		height: 100%;
		width: 100%;
	}
	.ReactModalPortal {
		position: relative;
		z-index: ${useZIndex("modal")};
	}
	.ReactModal__Overlay {
		display: flex;
		align-items: center;
		justify-content: space-around;
		background-color: rgba(32,55,82,0.3) !important;
	}
	.download::after {
		content: "";
		width: 24px;
		height: 24px;
		display: inline-block;
		background-image: url('/css/download.svg');
		background-size: contain;
		margin-left: 13px;
		position: relative;
		top: 5px;
	}
	.arrow-link {
		${typography.ButtonS}
		text-decoration: none;
		margin-top: 33px;
		@media (max-width: ${breakpoint.postMin.to}px) {
			font-size: 12px;
		}
	}
	.arrow-link__arrow{
		color: #2548FF;
		display: inline-block;
		margin-left: 9px;
	}
	.arrow-link:hover{
		text-decoration: underline;
	}
	#__react-alert__ {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		z-index: 10000;
		& > div > div {
			width: 100%;
		}
	}
	.slp{
		height: 225px !important;
	}
	.embed-video {
		display: flex;
		justify-content: center;
		background: #000;
		position: relative;
		padding-bottom: 56.25%;
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		img {
			position: absolute;
			top: calc(50% - 50px);
			left: calc(50% - 50px);
			width: 100px;
			height: 100px;
		}
	}
`
export default globalStyles
