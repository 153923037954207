import React from "react"
import { node, string } from "prop-types"
import StyledRow from "./style"

const Row = ({
	children,
	wrap,
	color,
	colorLeft,
	colorRight,
	justifyContent,
	...rest
}) => {
	return (
		<StyledRow
			wrap={wrap}
			color={color}
			colorLeft={colorLeft}
			colorRight={colorRight}
			justifyContent={justifyContent}
			{...rest}
		>
			<div className="in-row">{children}</div>
		</StyledRow>
	)
}

export const rowProps = {
	children: node,
	wrap: string,
	color: string,
	colorLeft: string,
	colorRight: string,
	justifyContent: string,
}

Row.defaultProps = {
	children: undefined,
	wrap: "nowrap",
	color: "transparent",
	colorLeft: undefined,
	colorRight: undefined,
	justifyContent: undefined,
}

Row.propTypes = rowProps

export default Row
