export const zIndex = {
	modal: 10000,
	header: 1000,
	body: 100,
	footer: 10,
}

export const useZIndex = index => {
	if (!(index in zIndex)) {
		return 0
	}
	return zIndex[index]
}

export const breakpoint = {
	preMax: {
		from: 1346,
		to: 1345,
	},
	postDefault: {
		from: 1131,
		to: 1130,
	},
	default: {
		from: 1071,
		to: 1070,
	},
	preMin: {
		from: 756,
		to: 750,
	},
	min: {
		from: 626,
		to: 625,
	},
	postMin: {
		from: 400,
		to: 399,
	},
}

const v = {
	headerHeight: "80px",
	leftSpace: "3.5%",
	rightSpace: "3.5%",
	minSidePadding: "16px",
}

export default v
