import styled from "styled-components"

import { color } from "./skin"
import { breakpoint } from "./var"

export const Heading1 = styled.h1`
	font-size: 64px;
	line-height: 110%;
	color: ${color.main.text1};
	@media (max-width: ${breakpoint.preMin.to}px) {
		font-size: 41px;
		line-height: 120%;
	}
	@media (max-width: ${breakpoint.postMin.to}px) {
		font-size: 36px;
	}
`

export const Heading2 = styled.h2`
	font-size: 51px;
	line-height: 120%;
	color: ${color.main.text1};
	@media (max-width: ${breakpoint.preMin.to}px) {
		font-size: 41px;
		line-height: 120%;
	}
	@media (max-width: ${breakpoint.postMin.to}px) {
		font-size: 36px;
	}
`

export const Heading3 = styled.h3`
	font-size: 41px;
	line-height: 120%;
	color: ${color.main.text1};
	@media (max-width: ${breakpoint.preMin.to}px) {
		font-size: 33px;
		line-height: 130%;
	}
`

export const Heading4 = styled.h4`
	font-size: 33px;
	line-height: 130%;
	color: ${color.main.text1};
	font-weight: 600;
	@media (max-width: ${breakpoint.preMin.to}px) {
		font-size: 26px;
		line-height: 130%;
	}
`

export const Heading5 = styled.h5`
	font-size: 26px;
	line-height: 130%;
	font-weight: 600;
	color: ${color.main.text1};
`

export const Heading6 = styled.h6`
	font-size: 21px;
	line-height: 130%;
	font-weight: 600;
	color: ${color.main.text1};
`

export const typography = {
	SubtitleL: `
		font-size: 18px;
		line-height: 160%;
		color: ${color.main.text3};
		@media (max-width: ${breakpoint.preMin.to}px) {
			font-size: 16px;
			line-height: 160%;
		}
	`,
	SubtitleM: `
		font-size: 16px;
		line-height: 160%;
	`,
	SubtitleS: `
	 font-size: 16px;
	 line-height: 160%;
 `,
	BodyXXL: `
 	font-size: 24px;
 	line-height: 160%;
 	@media (max-width: ${breakpoint.preMin.to}px) {
 		font-size: 18px;
 		line-height: 165%;
 	}
 `,
	BodyXL: `
 	font-size: 20px;
 	line-height: 160%;
 	@media (max-width: ${breakpoint.preMin.to}px) {
 		font-size: 18px;
 		line-height: 165%;
 	}
 `,
	BodyL: `
 	font-size: 18px;
 	line-height: 165%;
 `,
	BodyM: `
 	font-size: 16px;
 	line-height: 160%;
 `,
	BodyS: `
 font-size: 14px;
 line-height: 160%;
`,
	AllCapsBoldS: `
	font-weight: 600;
	font-size: 14px;
	line-height: 110%;
	letter-spacing: 0.07em;
	text-transform: uppercase;
`,
	AllCapsBoldXS: `
	font-weight: 600;
	font-size: 12px;
	line-height: 110%;
	letter-spacing: 0.1em;
	text-transform: uppercase;
`,
	LabelS: `
	font-size: 14px;
`,
	ButtonS: `
	font-weight: 600;
	font-size: 14px;
	text-transform: uppercase;
	letter-spacing: 0.08em;
`,
}
