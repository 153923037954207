import React, { Component } from "react"
import { element, string } from "prop-types"
import errorDefMSG from "../../../content/system/error.json"
import StyledErrorBoundary from "./style"

export default class ErrorBoundary extends Component {
	constructor(props) {
		super(props)
		this.state = { error: null }
	}

	componentDidCatch(error, errorInfo) {
		this.setState({ error })
		Sentry.configureScope(scope => {
			Object.keys(errorInfo).forEach(key => {
				scope.setExtra(key, errorInfo[key])
			})
		})
		Sentry.captureException(error)
	}

	render() {
		const { error } = this.state
		const { children, errorHeadline, errorParagraph, customMarkup } = this.props
		if (error) {
			// render fallback UI
			return (
				<StyledErrorBoundary>
					{customMarkup || (
						<>
							<h2>
								{errorHeadline === "" ? errorDefMSG.headline : errorHeadline}
							</h2>
							<p>
								{errorParagraph === "" ? errorDefMSG.paragraph : errorParagraph}
							</p>
						</>
					)}
				</StyledErrorBoundary>
			)
		}
		// when there's not an error, render children untouched
		return children
	}
}

const errorBoundaryProps = {
	children: element.isRequired,
	errorHeadline: string,
	errorParagraph: string,
}

ErrorBoundary.propTypes = {
	...errorBoundaryProps,
}

ErrorBoundary.defaultProps = {
	errorHeadline: "",
	errorParagraph: "",
}
