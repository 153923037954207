import React from "react"
import { node, object, func, shape, string } from "prop-types"
import { positions } from "react-alert"
import StyledAlert from "./style"
import Button from "../Link/Button"
import skin from "../_globalSettings/skin"

const Alert = ({ message, options, close, className, ...rest }) => {
	return (
		<StyledAlert {...rest} className={`${className} alert--${options.type}`}>
			<div className="alert-message">{message}</div>
			<Button
				type="button"
				className="alert__button"
				onClick={close}
				skin={skin.alert}
			>
				×
			</Button>
		</StyledAlert>
	)
}

export const options = {
	// https://github.com/schiehll/react-alert#options
	position: positions.TOP_CENTER,
	timeout: 4000,
}

Alert.propTypes = {
	message: node.isRequired,
	style: object, // eslint-disable-line
	close: func, // eslint-disable-line
	options: shape({
		type: string.isRequired,
	}).isRequired,
	className: string,
}

Alert.defaultProps = {
	className: "",
}

export default Alert
