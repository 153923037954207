import React from "react"
import { string, node, bool } from "prop-types"
import { Link } from "gatsby"

const A = ({ children, href, submit, className, ...rest }) => {
	if (submit) {
		return (
			<button type="submit" className={className} {...rest}>
				{children}
			</button>
		)
	}
	if (typeof href === "undefined" || href === "") {
		return (
			<button type="button" className={className} {...rest}>
				{children}
			</button>
		)
	}
	if (
		href.startsWith("mailto:") ||
		href.startsWith("tel:") ||
		href.startsWith("#")
	) {
		return (
			<a href={href} className={className} {...rest}>
				{children}
			</a>
		)
	}
	if (href.startsWith("/static/") || href.startsWith("/uploads/")) {
		return (
			<a href={href} className={`download ${className}`} {...rest} download>
				{children}
			</a>
		)
	}
	if (href.startsWith("/")) {
		return (
			<Link to={href} className={className} {...rest}>
				{children}
			</Link>
		)
	}
	return (
		<a
			href={href}
			target="blank"
			rel="noopener noreferrer"
			className={`external ${className}`}
			{...rest}
		>
			{children}
		</a>
	)
}

export const AProps = {}

A.propTypes = {
	...AProps,
	children: node.isRequired,
	submit: bool,
	className: string,
	href: string,
}

A.defaultProps = {
	submit: false,
	className: "",
	href: undefined,
}

export default A
