import React from "react"
import { shape, string } from "prop-types"
import { useAlert } from "react-alert"
import Button from "../Link/Button"
import skin from "../_globalSettings/skin"
import Image from "../Image"
import SubmitImage from "../../images/icons/submit-arrow.svg"

const FormSubmit = ({ text, status, customSkin, ...rest }) => {
	const alert = useAlert()
	const buttonContent = text.label ? (
		text.label
	) : (
		<Image data={SubmitImage} alt="Odeslat" />
	)
	return (
		<>
			<div className="form-item" style={{ alignSelf: "flex-end" }}>
				<Button
					href=""
					skin={
						status === "submitting" ? skin.darkGhost : customSkin || skin.blue
					}
					disabled={status === "submitting"}
					className="form-submit"
					submit
					onClick={() => {
						if (status !== "success") {
							alert.show(text.error, { type: "error" })
						}
					}}
					{...rest}
				>
					{status === "submitting" ? text.submitting : buttonContent}
				</Button>
			</div>

			{/* <div className="form-item">
				{status === "error" ? <p className="form-error">{text.error}</p> : null}
				{status === "success" ? (
					<p className="form-success">{text.success}</p>
				) : null}
			</div> */}
		</>
	)
}

FormSubmit.propTypes = {
	text: shape({
		label: string.isRequired,
		submitting: string.isRequired,
		error: string.isRequired,
		success: string.isRequired,
	}).isRequired,
	status: string,
	customSkin: shape({
		border: shape({
			default: string.isRequired,
			hover: string.isRequired,
			pressed: string.isRequired,
		}).isRequired,
		background: shape({
			default: string.isRequired,
			hover: string.isRequired,
			pressed: string.isRequired,
		}).isRequired,
		text: shape({
			default: string.isRequired,
			hover: string.isRequired,
			pressed: string.isRequired,
		}).isRequired,
		shadow: shape({
			default: string.isRequired,
			hover: string.isRequired,
			pressed: string.isRequired,
		}).isRequired,
	}),
}

FormSubmit.defaultProps = {
	status: undefined,
	customSkin: undefined,
}

export default FormSubmit
