import React from "react"
import { Formik, Form } from "formik"
import { object, string, func } from "prop-types"
import { useAlert } from "react-alert"
import ErrorBoundary from "../ErrorBoundary"
import FormItem from "./FormItem"
import FormSubmit from "./FormSubmit"
import sendMessage from "../ContactService"
import StyledForm from "./style"
import useGlobal from "../../store"

const OnlyForm = ({ data, id, submitProps, beforeSubmit }) => {
	const alert = useAlert()
	const [globalState, globalActions] = useGlobal()

	const getInitialValues = resource =>
		resource.fieldset.reduce((mainObj, item) => {
			return {
				...mainObj,
				...item.input.reduce((obj, subitem) => {
					return {
						...obj,
						[subitem.name]: subitem.initialValue,
					}
				}, {}),
			}
		}, {})
	const init = getInitialValues(data)
	if (data.hidden) init[data.hidden.name] = data.hidden.value

	return (
		<ErrorBoundary
			customMarkup={
				<div>
					<h3>Něco se pokazilo</h3>
					<p>
						Kontaktujte nás prosím pomocí emailu
						<a href="mailto:info@aswa.cz">info@aswa.cz</a>
					</p>
				</div>
			}
		>
			<Formik
				initialValues={init}
				onSubmit={async (values, { setSubmitting, resetForm, setStatus }) => {
					const newValues = beforeSubmit(values)
					setStatus("submitting")
					setSubmitting(true)
					alert.removeAll()
					sendMessage(newValues)
						.then(() => {
							setSubmitting(false)
							setStatus("success")
							resetForm({})
							alert.show(data.submit.success, { type: "success" })
							if (globalState.modalOpen) {
								globalActions.modalOpen(false)
							}
						})
						.catch(() => {
							setSubmitting(false)
							setStatus("error")
							alert.show(data.submit.error, { type: "error" })
						})
				}}
			>
				{({ status, values, errors, touched, setFieldValue }) => {
					return (
						<Form>
							<StyledForm>
								{data.fieldset.map((item, i) => (
									<div
										className="form__fieldset"
										key={`fieldset__${i}`} // eslint-disable-line
									>
										{item.headline && (
											<p className="form__headline">{item.headline}</p>
										)}
										{item.input.map((subitem, subI) => {
											if (
												subitem.field === "input" ||
												subitem.field === "textarea" ||
												subitem.field === "select"
											) {
												return (
													<FormItem
														key={`field-input__${subI}`} // eslint-disable-line
														item={subitem}
														value={values[subitem.name]}
														error={errors[subitem.name]}
														touched={touched[subitem.name]}
														formId={id}
														setFieldValue={setFieldValue}
													/>
												)
											}
											if (subitem.field === "string") {
												return (
													<p
														className="form__content"
														key={`field-string__${subI}`} // eslint-disable-line
													>
														{subitem.content}
													</p>
												)
											}
											return ""
										})}
									</div>
								))}
								<div
									className="form__fieldset form__submit"
									style={{ flexDirection: "column" }}
								>
									<FormSubmit
										text={data.submit}
										status={status}
										{...submitProps}
									/>
								</div>
								{data.hidden && (
									<input
										type="hidden"
										value={data.hidden.value}
										name={data.hidden.name}
									/>
								)}
							</StyledForm>
						</Form>
					)
				}}
			</Formik>
		</ErrorBoundary>
	)
}

OnlyForm.propTypes = {
	id: string.isRequired,
	submitProps: object, // eslint-disable-line
	data: object.isRequired, // eslint-disable-line
	beforeSubmit: func,
}

OnlyForm.defaultProps = {
	submitProps: undefined,
	beforeSubmit: values => values,
}

export default OnlyForm
