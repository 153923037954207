import React from "react"
import { oneOfType, string, shape, number } from "prop-types"
import GatsbyImg from "gatsby-image/withIEPolyfill"

const Image = ({ data, className, alt, ...rest }) => {
	if (typeof data === "string") {
		return (
			<img
				src={data}
				alt={alt}
				className={`gatsby-image-wrapper ${className}`}
				{...rest}
			/>
		)
	}
	if (data.extension.toLowerCase() === "svg") {
		return (
			<img
				src={data.publicURL}
				alt={alt}
				className={`gatsby-image-wrapper ${className}`}
				{...rest}
			/>
		)
	}
	if (
		typeof data.childImageSharp !== "undefined" &&
		data.childImageSharp.fixed
	) {
		return (
			<GatsbyImg
				alt={alt}
				fixed={data.childImageSharp.fixed}
				className={className}
				{...rest}
			/>
		)
	}
	if (
		typeof data.childImageSharp !== "undefined" &&
		data.childImageSharp.fluid
	) {
		return (
			<GatsbyImg
				alt={alt}
				fluid={data.childImageSharp.fluid}
				className={className}
				{...rest}
			/>
		)
	}
	return null
}

Image.propTypes = {
	data: oneOfType([
		string,
		shape({
			childImageSharp: shape({
				fluid: shape({
					aspectRatio: number,
					sizes: string,
					src: string,
					srcSet: string,
					// srcSetWebp: string,
					// srcWebp: string,
				}).isRequired,
			}),
		}),
	]).isRequired,
	className: string,
	alt: string.isRequired,
}

Image.defaultProps = {
	className: "",
}

export default Image
