import React from "react"
import { string, shape } from "prop-types"
import styled from "styled-components"
import { Link } from "gatsby"
import A from "./index"
import { typography } from "../_globalSettings/typography"

const StyledButton = styled(A)`
	font-family: "Graphik Web", sans-serif, serif;
	padding: 0 32px;
	${typography.ButtonS};
	display: inline-block;
	text-decoration: none;
	text-transform: uppercase;
	border-radius: 4px;
	text-align: center;
	height: 62px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 2px solid ${props => props.skin.border.default};
	box-shadow: ${props => props.skin.shadow.default};
	color: ${props => props.skin.text.default};
	background-color: ${props => props.skin.background.default};
	transition: background-color 0.4s, box-shadow 0.4s, color 0.4s;
	&:hover:not(:disabled),
	&:focus:not(:disabled) {
		background-color: ${props => props.skin.background.hover};
		box-shadow: ${props => props.skin.shadow.hover};
		color: ${props => props.skin.text.hover};
	}
	&:active {
		background-color: ${props => props.skin.background.pressed};
		box-shadow: ${props => props.skin.shadow.pressed};
		color: ${props => props.skin.text.pressed};
	}
	&.close {
		font-size: 30px;
		line-height: 30px;
		width: 40px;
		padding: 5px;
		text-align: center;
	}
	cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
`

const StyledLink = styled(Link)`
	text-decoration: none;
`

const Button = ({ skin, to, ...rest }) => {
	if (to) {
		return (
			<StyledLink to={to}>
				<StyledButton skin={skin} {...rest} />
			</StyledLink>
		)
	}
	return <StyledButton skin={skin} {...rest} />
}

Button.propTypes = {
	skin: shape({
		color: shape({
			default: string.isRequired,
			hover: string.isRequired,
			pressed: string.isRequired,
		}),
		shadow: shape({
			default: string.isRequired,
			hover: string.isRequired,
			pressed: string.isRequired,
		}),
		border: shape({
			default: string.isRequired,
			hover: string.isRequired,
			pressed: string.isRequired,
		}),
		background: shape({
			default: string.isRequired,
			hover: string.isRequired,
			pressed: string.isRequired,
		}),
	}).isRequired,
	to: string,
}

Button.defaultProps = {
	to: undefined,
}

export default Button
