import styled from "styled-components"

const StyledAlert = styled.div`
	color: white;
	width: 100%;
	min-height: 80px;
	box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.03);
	margin: 0 !important;
	position: relative;
	display: flex;
	padding: 17px;
	align-items: center;
	justify-content: space-evenly;
	.alert__button {
		font-size: 30px;
		line-height: 30px;
		font-weight: normal;
		padding: 5px;
		width: 40px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 5px;
	}
	&.alert--error {
		background-color: #e9386d;
	}
	&.alert--success {
		background-color: #36cbc2;
	}
`

export default StyledAlert
