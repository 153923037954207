import styled from "styled-components"
import v, { breakpoint } from "../../../_globalSettings/var"

const StyledRow = styled.div`
	background-color: ${props => props.color};
	width: 100%;
	${props =>
		props.colorLeft &&
		props.colorRight &&
		`background: linear-gradient(90deg, ${props.colorLeft} 50%, ${props.colorRight} 50%);`}
	.in-row {
		max-width: 1380px;
		padding: 0 50px;
		width: 100%;
		display: flex;
		margin: auto;
		flex-wrap: ${props => props.wrap};
		position: relative;
	}
	@media (max-width: ${breakpoint.default.to}px) {
		background: ${props => props.colorLeft && props.colorLeft};
		.in-row {
			padding-left: ${v.minSidePadding};
			padding-right: ${v.minSidePadding};
		}
	}
`

export default StyledRow
