import styled from "styled-components"
import { breakpoint } from "../_globalSettings/var"

const StyledForm = styled.div`
	width: 100%;
	background-color: white;
	display: flex;
	flex-direction: column;
	position: relative;

	.form-label {
		display: inline-block;
		padding-bottom: 4px;
	}

	.form__fieldset {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -15px;
		padding-bottom: 40px;
	}
	.form-input {
		display: block;
		background: rgba(13, 22, 54, 0.05);
		border: 1px solid rgba(243, 243, 245, 0.05);
		outline-width: 0;
		box-sizing: border-box;
		border-radius: 2px;
		font-size: 16px;
		line-height: 24px;
		padding: 8px 10px;
		margin-bottom: 5px;
		color: #374562;
		width: 100%;
		&:focus {
			border: 1px solid #2944d1;
		}
		&::placeholder {
			color: #374562;
		}
	}
	.form-item {
		display: flex;
		flex-direction: column;
		padding: 0 15px;
		width: 100%;
	}
	.form-submit {
		margin-bottom: 20px;
		align-self: flex-end;
	}
	.form-field-error {
		display: block;
		height: 15px;
		width: 100%;
		color: #e9386d;
		font-size: 12px;
		line-height: 12px;
		margin-bottom: 10px;
	}
	.form-submitting {
		width: 100%;
		padding: 17px;
		color: white;
		background-color: #4d81a6;
		text-align: center;
	}

	.invalid-input {
		border: 1px solid #e9386d !important;
	}

	.valid-input {
		margin-bottom: 30px;
	}

	.form__headline {
		width: 100%;
		font-weight: 600;
		font-size: 21px;
		line-height: 30px;
		letter-spacing: -0.02em;
		padding: 0 15px 10px 15px;
	}

	.form__content {
		width: 100%;
		font-size: 18px;
		line-height: 160%;
		padding: 0 15px 10px 55px;
		background-image: url("/css/info-circle.svg");
		background-repeat: no-repeat;
		background-position-x: 15px;
	}

	.half-width {
		width: 50%;
	}

	.full-width {
		width: 100%;
	}

	@media (max-width: ${breakpoint.min.to}px) {
		.half-width {
			width: 100%;
		}
	}
`

export default StyledForm
