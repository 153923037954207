import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import OGImage from "../../images/preview.png"

function SEO({ description, lang, meta, title, robots, image }) {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						author
						siteUrl
					}
				}
			}
		`
	)

	const metaDescription =
		typeof description === "undefined" ||
		description === null ||
		description === ""
			? `${site.siteMetadata.description}`
			: `${description}`

	const metaTitle =
		typeof title === "undefined" || title === null || title === ""
			? `${site.siteMetadata.title}`
			: `${title} | ${site.siteMetadata.title}`

	const metaImage =
		typeof image === "undefined" || image === null || image === ""
			? `${site.siteMetadata.siteUrl}${OGImage}`
			: image

	const metas = [
		{
			name: `description`,
			content: metaDescription,
		},
		{
			property: `og:title`,
			content: metaTitle,
		},
		{
			property: `og:description`,
			content: metaDescription,
		},
		{
			property: `og:type`,
			content: `website`,
		},
		{
			property: `og:image`,
			content: metaImage,
		},
		{
			name: `twitter:card`,
			content: `summary`,
		},
		{
			name: `twitter:creator`,
			content: site.siteMetadata.author,
		},
		{
			name: `twitter:title`,
			content: metaTitle,
		},
		{
			name: `twitter:description`,
			content: metaDescription,
		},
		meta,
	]

	if (robots !== null && robots !== "" && typeof robots !== "undefined")
		metas.push({ name: "robots", content: robots })

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={metaTitle}
			meta={metas}
		/>
	)
}

export const SEOProps = {
	robots: PropTypes.string,
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string,
}

SEO.defaultProps = {
	robots: null,
	lang: `cs`,
	meta: [],
	description: ``,
	title: ``,
}
SEO.propTypes = {
	...SEOProps,
}

export default SEO
